import React, { useEffect, useState } from "react";
import { client, downloadBlobFile } from "../../utils/useRequest";
import { GridColDef } from "@mui/x-data-grid";
import DatatableFilter from "../../component/datatable/DatatableFilter";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ViewCsv from "./ViewCsv";
import { Field, FieldProps, Form, Formik } from "formik";
import LoadingButton from "../../component/form-component/LoadingButton";
import Editor from "../../component/form-component/Editor";
import CheckBoxField from "../../component/form-component/CheckBoxField";
import toastr from "toastr";
import ChangeLog from "./ChangeLog";
import SelectTable from "../../component/form-component/SelectTable";
import _ from "lodash";
import ColumnReorder from "../../component/datatable/ColumnReorder";
import TextField from "../../component/form-component/TextField";
import EditClaimPaymentComponent from "./EditClaimPaymentComponent";
import * as yup from "yup";
import FileDropzone from "../../component/FileDropzone";

const filter = {
  processors: {
    name: "processor",
    type: "table",
    isMulti: false,
    bucket: true,
    table: "processor",
    filterBy: "processor_id",
  },
  claim_status: {
    name: "status",
    type: "table",
    isMulti: false,
    bucket: true,
    table: "claim_status",
    filterBy: "claim_status",
  },
};

const extraSearch = [
  {
    title: "Patient Birthday",
    type: "date",
    name: "patient_birthday",
  },
  {
    title: "Patient Phone",
    type: "text",
    name: "patient_phone",
  },
  {
    title: "Patient Phone Formatted",
    type: "phone",
    name: "patient_phone_formatted",
  },
  {
    title: "Rework Date",
    type: "date",
    name: "rework_date",
  },
  {
    title: "Employer Phone",
    type: "phone",
    name: "employer_phone",
  },
  {
    title: "Insurance Phone",
    type: "phone",
    name: "insurance_phone",
  },
  {
    title: "Service Facility Phone",
    type: "phone",
    name: "service_phone",
  },

];

const Claims = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const { user } = useSelector((state: any) => state.user);
  const [split, setSplit] = useState(false);
  const [rowView, setRowView] = useState(0);
  const [checkedId, setCheckedId] = useState([] as any);
  const [tableChanged, setTableChanged] = useState("" as any);

  const exportCsv = async (id: number) => {
    const url = `/api/claim/export/${id}`;
    const fileName = `Claim B-${id}.csv`;
    await downloadBlobFile(url, fileName);
  };

  const downloadPdf = async (id: number) => {
    const url = `/api/claim/export-pdf/${id}`;
    const fileName = `Claim B-${id}.pdf`;
    await downloadBlobFile(url, fileName);
  };

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/claims/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Claim</a>
          </li>
          <li>
            <a className="dropdown-item" onClick={() => exportCsv(params.id)}>
              Export CSV
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              onClick={() => {
                setRowView(params.id);
                setSplit(true);
              }}
            >
              View CSV
            </a>
          </li>
          <li>
            <a className="dropdown-item" onClick={() => downloadPdf(params.id)}>
              Download Pdf
            </a>
          </li>
          <li>
            <Invoice
              id={[params.id]}
              isPatient={true}
              title="Patient Invoice"
              type={"patient_invoice"}
              fileName={`Patient Invoice`}
            />
          </li>
          <li>
            <Invoice
              id={[params.id]}
              isPatient={false}
              title="Company Invoice"
              type={"company_invoice"}
              fileName={`Company Invoice`}
            />
          </li>
          <li>
            <ChangeLog modelName="Claim" subjectId={params.id} />
          </li>
          <li>
            <SendEmail label="Spanish Patient Letter" type="spanish" claim={params} />
          </li>
          <li>
            <SendEmail label="Employer Letter" type="employer" claim={params} />
          </li>
          <li>
            <SendEmail label="Patient Letter" type="patient" claim={params} />
          </li>
        </ul>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 100,
      filterable: false,
    },
    {
      field: "id",
      headerName: "Claim Id",
      width: 100,
      renderCell: (row: any) => {
        return (
          <Link to={`/${role}/claims/${row.id}/edit`} className="text-primary">
            B-{row.id}
          </Link>
        );
      },
    },
    { field: "patient_first_name", headerName: "First name", width: 130 },
    { field: "patient_last_name", headerName: "Last name", width: 130 },
    {
      field: "processor_id",
      headerName: "Processor",
      width: 130,
      renderCell: (row: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={row.row.processor?.processor_name}
          >
            {row.row?.processor?.processor_name}
          </div>
        );
      },
    },
    {
      field: "patient_state_code",
      headerName: "State",
      width: 130,
    },
    {
      field: "date_of_injury",
      headerName: "Date Of Injury",
      width: 130,
    },
    {
      field: "checkoff_date",
      headerName: "Check Off",
      width: 130,
      type: "date",
    },
    {
      field: "rework_date",
      headerName: "Rework Date",
      width: 130,
      type: "date",
    },
    {
      field: "date_of_service",
      headerName: "Date Of Service",
      width: 130,
      type: "date",
    },
    {
      field: "insurance_company_name",
      headerName: "Insurance Company Name",
      width: 200,
    },
    { field: "employer_name", headerName: "Employer Name", width: 130 },
    { field: "insurance_id_number", headerName: "Claim Number", width: 130 },
    {
      field: "date_received",
      headerName: "Date Referal Received",
      width: 160,
      type: "date",
    },
    {
      field: "claim_status",
      headerName: "Claim Status",
      width: 130,
      renderCell: (row: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={row.row?.status?.claim_status_name}
          >
            {row.row?.status?.claim_status_name}
          </div>
        );
      },
    },
    {
      field: "created_by",
      headerName: "Entered By",
      width: 130,
      renderCell: (row: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={row.row?.created_by?.name}
          >
            {row.row?.created_by?.name}
          </div>
        );
      },
    },
    {
      field: "action_needed",
      headerName: "Action Needed",
      width: 130,
      filterable: true,
    },
    {
      field: "patient_birthday",
      headerName: "Patient Birthday",
      width: 130,
      type: "date",
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 130,
      type: "date",
    },
  ];

  let url = "/api/claims";

  // for column reorder
  let columnStorage = localStorage.getItem(url + "column-reorder");

  const [newColumn, setNewColumn] = useState(
    !!columnStorage
      ? _.sortBy(columns, (obj: any) => _.findIndex(JSON.parse(columnStorage ?? ""), { 'field': obj.field }))
      : (columns as any)
  );



  const getRowClassName = (params: any) => {
    const userId = params.row.qa_flag?.user_id;
    if (userId == user?.id) {
      return "bg-warning";
    }
    return "";
  };

  const checkOffNow = async (checkedId: any) => {
    const response = await client.post(`/api/claim/check-off-now`, checkedId);

    if (response.status) {
      toastr.success("Check Off Successfully");
      setTableChanged(response);
    }
  };

  const CheckBoxAction = () => {
    return (
      <div className="table-action dropend mx-1 mt-1">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li>
            <a
              className="dropdown-item"
              onClick={() => {
                if (checkedId.length > 0) {
                  checkOffNow(checkedId);
                } else {
                  toastr.error("No rows are selected");
                }
              }}
            >
              Check Off Now
            </a>
          </li>
          <li>
            <ChangeStatus ids={checkedId} setTableChanged={setTableChanged} />
          </li>
          <li>
            <Invoice
              id={checkedId}
              isPatient={true}
              title="Patient Invoice"
              type={"patient_invoice"}
              fileName={`Patient Invoice`}
            />
          </li>
          <li>
            <Invoice
              id={checkedId}
              isPatient={false}
              title="Company Invoice"
              type={"company_invoice"}
              fileName={`Company Invoice`}
            />
          </li>
          <li>
            <ChangeProcessor ids={checkedId} setTableChanged={setTableChanged} />
          </li>
          <li>
            <ChangeReworkDate ids={checkedId} setTableChanged={setTableChanged} />
          </li>
          <li>
            <ActionNeeded ids={checkedId} setTableChanged={setTableChanged} />
          </li>
          <li>
            <ClientPaidDate ids={checkedId} setTableChanged={setTableChanged} />
          </li>
          <li>
            <MassPayment ids={checkedId} setTableChanged={setTableChanged} />
          </li>
        </ul>
      </div>
    );
  };

  return (
    <DatatableFilter
      rowCondition={getRowClassName}
      defaultFilter={{ mode: "bucket", filterBy: "all" }}
      filterMode={["dropdown", "bucket"]}
      filter={filter}
      heading="Claims"
      columns={newColumn}
      url={url}
      addButton={true}
      split={split}
      splitComponent={
        <ViewCsv id={rowView} closeSplit={() => setSplit(false)} />
      }
      extraSearch={extraSearch}
      rowHeight={30}
      mutate={tableChanged}
      checkBoxSelection={true}
      checkedRow={setCheckedId}
      checkBoxAction={<CheckBoxAction />}
      columnReorder={<ColumnReorder columView={newColumn} setColumnView={setNewColumn} url={url} />}
    />
  );
};

export default Claims;

interface InvoiceProps {
  title: string;
  id: any;
  type: any;
  isPatient: boolean;
  fileName: string;
}
const Invoice: React.FC<InvoiceProps> = ({
  title,
  id,
  type,
  isPatient,
  fileName,
}) => {
  const [toggle, setToggle] = useState(false);
  const generatePdf = async (values: any) => {
    const response = await client.post(
      `/api/claim/download-pdf`,
      { ...values, ids: id },
      { responseType: "blob" }
    );

    setToggle(false);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <a
        className="dropdown-item"
        onClick={() => {
          if (id.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        {title}
      </a>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Formik initialValues={{ type: type } as any} onSubmit={generatePdf}>
            {({ handleSubmit, isSubmitting, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    {isPatient && (
                      <Col md={12}>
                        <Editor
                          label="Patient Information"
                          name="invoice_information"
                        />
                      </Col>
                    )}
                    <Col md={12}>
                      <CheckBoxField
                        name="include_invoice"
                        label="Include Invoice"
                        checked={!!values.include_invoice}
                      />
                    </Col>
                  </Row>
                  <div className="mt-3">
                    <LoadingButton
                      title={"Generate"}
                      isLoading={isSubmitting}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export { ChangeLog, Invoice };

interface ChangeStatusProps {
  ids: any;
  setTableChanged: any;
}
const ChangeStatus: React.FC<ChangeStatusProps> = ({
  ids,
  setTableChanged,
}) => {
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState(null as any);

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/change-status`, {
      ids: ids,
      ...value,
    });

    if (response.status) {
      toastr.success("Claim Status Changed Successfully");
      setTableChanged(response);
    }
  };
  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          if (ids.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        Change Status
      </a>
      <Modal isOpen={toggle}>
        <ModalHeader>Change Status</ModalHeader>
        <ModalBody>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <SelectTable
                      tabIndex={10}
                      name="claim_status"
                      label="Claim Status"
                      table="claim_status"
                    />
                  </Row>

                  <div className="mt-3">
                    <LoadingButton title={`Change`} isLoading={isSubmitting} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

interface ChangeProcessorProps {
  ids: any;
  setTableChanged: any;
}
const ChangeProcessor: React.FC<ChangeProcessorProps> = ({
  ids,
  setTableChanged,
}) => {
  const [toggle, setToggle] = useState(false);

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/change-processor`, {
      ids: ids,
      ...value,
    });

    if (response.status) {
      toastr.success("Claim Processor Changed Successfully");
      setTableChanged(response);
    }
  };
  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          if (ids.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        Change Processor
      </a>
      <Modal isOpen={toggle}>
        <ModalHeader>Change Processor</ModalHeader>
        <ModalBody>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <SelectTable
                      tabIndex={10}
                      name="processor_id"
                      label="Processor"
                      table="processor"
                    />
                  </Row>

                  <div className="mt-3">
                    <LoadingButton title={`Change`} isLoading={isSubmitting} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};


interface ChangeReworkDateProps {
  ids: any;
  setTableChanged: any;
}
const ChangeReworkDate: React.FC<ChangeReworkDateProps> = ({
  ids,
  setTableChanged,
}) => {
  const [toggle, setToggle] = useState(false);

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/change-rework-date`, {
      ids: ids,
      ...value,
    });

    if (response.status) {
      toastr.success("Claim Processor Changed Successfully");
      setTableChanged(response);
    }
  };
  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          if (ids.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        Change Rework Date
      </a>
      <Modal isOpen={toggle}>
        <ModalHeader>Change Rework Date</ModalHeader>
        <ModalBody>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <TextField
                      type={"date"}
                      tabIndex={5}
                      label="Rework Date"
                      placeholder={"mm/dd/yyyy"}
                      name="rework_date"
                    />
                  </Row>

                  <div className="mt-3">
                    <LoadingButton title={`Change`} isLoading={isSubmitting} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

interface SetActionNeededProps {
  ids: any;
  setTableChanged: any;
}

const ActionNeeded: React.FC<SetActionNeededProps> = ({ ids, setTableChanged }) => {
  const [toggle, setToggle] = useState(false);

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/change-action-needed`, {
      ids: ids,
      ...value,
    });

    if (response.status) {
      toastr.success("Claim Action Needed Changed Successfully");
      setTableChanged(response);
    }
  };
  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          if (ids.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        Change Action Needed
      </a>
      <Modal isOpen={toggle}>
        <ModalHeader>Change Action Needed</ModalHeader>
        <ModalBody>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <TextField
                      type={"text"}
                      tabIndex={5}
                      label="Action needed"
                      placeholder={"Action Needed"}
                      name="action_needed"
                    />
                  </Row>

                  <div className="mt-3">
                    <LoadingButton title={`Change`} isLoading={isSubmitting} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

interface ClientPaidDateProps {
  ids: any;
  setTableChanged: any;
}

const ClientPaidDate: React.FC<ClientPaidDateProps> = ({ ids, setTableChanged }) => {
  const [toggle, setToggle] = useState(false);

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/client-paid-date`, {
      ids: ids,
      ...value,
    });

    if (response.status) {
      toastr.success("Client Paid Date Changed Successfully");
      setTableChanged(response);
    }
  };
  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          if (ids.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        Change Client Paid Date
      </a>
      <Modal isOpen={toggle}>
        <ModalHeader>Change Client Paid Date</ModalHeader>
        <ModalBody>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <TextField
                      type={"date"}
                      tabIndex={5}
                      label="Client Paid Date"
                      placeholder={"mm/dd/yyyy"}
                      name="client_paid_date"
                    />
                  </Row>

                  <div className="mt-3">
                    <LoadingButton title={`Change`} isLoading={isSubmitting} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}


interface MassPaymentProps {
  ids: any;
  setTableChanged: any;
}

const MassPayment: React.FC<MassPaymentProps> = ({ ids, setTableChanged }) => {
  const [toggle, setToggle] = useState(false);
  const [claimPaymentCount, setClaimPaymentCount] = useState([] as any);
  const [showPayment, setShowPayment] = useState(false);

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/mass-payment`, {
      ids: ids,
      ...value,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status) {
      toastr.success("Mass Payment Created Successfully");
      setTableChanged(response);
    }
  };

  const fetchClaimPayment = async () => {
    const response = await client.post('/api/claim/fetch-claim-payment', {
      ids: ids
    })

    if (response.status) {
      const count = response.data.data.reduce((a: any, b: any) => {
        return parseInt(a) + parseInt(b.claim_payments_count);
      }, 0);
      if (count === 0) {
        setShowPayment(true);
      }
      setClaimPaymentCount(response.data.data)
    }
  }

  useEffect(() => {
    if (toggle && ids.length > 0) {
      fetchClaimPayment()
    }
  }, [ids, toggle])

  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          if (ids.length > 0) {
            setToggle(!toggle);
          } else {
            toastr.error("No rows are selected");
          }
        }}
      >
        Mass Payment
      </a>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>Mass Payment</ModalHeader>
        <ModalBody>
          {claimPaymentCount.length > 0 && !showPayment && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Claim Id</th>
                  <th scope="col">Payment Count</th>
                </tr>
              </thead>
              <tbody>
                {claimPaymentCount.map((item: any, i: number) => {

                  return (
                    <tr key={i}>
                      <th scope="row">{item.id}</th>
                      <td>{item.claim_payments_count}</td>
                    </tr>
                  )
                })}
              </tbody> <br />
              <button className="btn btn-secondary" onClick={() => setShowPayment(true)}>Do you want to proceed?</button>
            </table>
          )}
          {showPayment && (
            <EditClaimPaymentComponent extraComponent={
              <Row>
                <Col md="12">
                <Field name={"attachments"}>
              {({
                field,
                form: { touched, errors, setFieldValue, values },
                meta,
              }: FieldProps) => (
                <FileDropzone
                  media={values.attachments ?? []}
                  onChange={(files: any) => {
                    setFieldValue("attachments", files);
                  }}
                  multiple
                />
              )}
            </Field>
                </Col>
              </Row>
            } onSubmit={onSubmit} />
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

interface SendEmailProps {
  claim: any;
  type: any;
  label: any;
}

const SendEmail: React.FC<SendEmailProps> = ({ claim, type, label }) => {
  const [toggle, setToggle] = useState(false);
  const { user } = useSelector((state: any) => state.user);
  const SendEmailSchema = yup.object().shape({
    send_from: yup.string().required("Send from is required").email('Invalid email address'),
    send_to: yup.string().required("Send to is required").email('Invalid email address'),
    subject: yup.string().required("Subject is required"),
    content: yup.string().required("Content is required"),
    email_cc: yup.string().email('Invalid email address')
  });

  const onSubmit = async (value: any) => {
    const response = await client.post(`/api/claim/send-email`, {
      id: claim.id,
      ...value,
    });

    if (response.status) {
      toastr.success("Email sent successfully");
      setToggle(false);
    }
  };
  let text: any;
  switch (true) {
    case type === 'spanish':
      text = `<p><span style="color: rgb(0, 0, 0);background-color: transparent">Hola&nbsp;</span></p><p><span style="color: rgb(0, 0, 0);background-color: transparent">${claim.row.patient_first_name},</span></p><p><span style="color: rgb(0, 0, 0);background-color: transparent">Hemos intentado ponernos en contacto con usted en relación con el equipo médico duradero que se le proporcionó el ${claim.row.date_of_service} en ${claim.row.service_company_name}.</span></p><p><span style="color: rgb(0, 0, 0);background-color: transparent">Nuestros registros indican que este artículo fue proporcionado a usted como tratamiento para una lesión relacionada con el trabajo. Nos falta información sobre su compañía de seguro de compensación laboral. Por favor, póngase en contacto con nuestra oficina en 888-722-7599, extensión 108 o correo electrónico WCBilling@eazemed.com y proporcionar la siguiente información:<em>​</em></span></p><ul><li><p><span style="background-color: transparent">Nombre y dirección de la aseguradora de indemnización laboral</span></p></li><li><p><span style="background-color: transparent">Número de reclamación</span></p></li><li><p><span style="background-color: transparent">Nombre y número de teléfono del perito</span></p></li></ul><p><span style="color: rgb(0, 0, 0);background-color: transparent">Si no conoce la información de su seguro de indemnización laboral, ¿podría proporcionar la información de contacto de su empleador?</span></p><p><span style="color: rgb(0, 0, 0);background-color: transparent">Si tiene alguna pregunta, póngase en contacto con nuestra oficina.</span></p><p><span style="color: rgb(0, 0, 0);background-color: transparent">Muchas gracias,</span></p>`;
      break;
    case type === 'patient':
      text = `<p><span style="font-size:15px;color:#000000;background-color:transparent">Hello&nbsp;</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">${claim.row.patient_first_name},</span></p><p><br></p><p><span style="font-size:15px;color:#000000;background-color:transparent">We have been attempting to contact you regarding durable medical equipment that was provided to you on ${claim.row.date_of_service} at ${claim.row.service_company_name}.</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">Our records indicate that this item was provided to you as a treatment for a work-related injury. We are missing information about your workers’ compensation insurance carrier. Can you please contact our office at 888-722-7599, extension 108 to provide the missing information. This is what we are missing:</span></p><ul><li style="font-size: 15px; color: rgb(0, 0, 0);"><p><span style="background-color: transparent;">Workers’ compensation carrier name and address</span></p></li><li style="font-size: 15px; color: rgb(0, 0, 0);"><p><span style="background-color: transparent;">Claim number</span></p></li></ul><p><span style="font-size: 15px; color: rgb(0, 0, 0); background-color: transparent;">If you do not know your workers’ compensation information, can you please provide contact information for your employer?</span></p><p>​</p><p><span style="background-color: transparent; color: rgb(0, 0, 0); font-size: 15px; font-weight: var(--bs-body-font-weight); text-align: var(--bs-body-text-align);">If you have any questions, please contact our office.</span></p><br><p><span style="font-size:15px;color:#000000;background-color:transparent">Thank you,</span></p><p><br></p><p><strong><br></strong></p><p></p>`
      break;
    case type === 'employer':
      text = `<p><span style="font-size:15px;color:#000000;background-color:transparent">Hello&nbsp;</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">${claim.row.employer_name},</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">Please see the attached invoices for medical equipment provided to employee ${claim.row.patient_first_name} ${claim.row.patient_last_name} on ${claim.row.date_of_service} at ${claim.row.service_company_name}.</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">Information collected at the time of the visit indicates that this equipment was provided for a work-related injury, and we have not been successful in obtaining workers’ compensation insurance information necessary for billing.</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">To provide workers compensation information, or if you have any questions, please contact our billing department at 888-722-7599, extension 108. You can also email us at wcbilling@eazemed.com</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">.</span></p><p><span style="font-size:15px;color:#000000;background-color:transparent">Thank you,</span></p>`
      break;
    default:
      text = ""

  }
  console.log('ufsa', user)
  text = text + `<div style="margin-top:32px; font-size: 13px">
  <table class="box-section"
      width="100%"
      cellspacing="0"
      cellpadding="0"
      style="margin: 0 auto; table-layout: fixed; border-spacing: 0;">
      <tbody>
          <tr>
              <td style="border-collapse: collapse; width: 80px;border:unset;"
                  align="right">
                  <img src="https://eazemedical.s3.us-west-2.amazonaws.com/logo/email_logo.jpeg"
                      alt="logo"
                      style="width: 90px; height: auto;">
              </td>
              <td style="border-collapse: collapse; padding-left: 16px;border:unset;"
                  align="left">
                  <div>${user.name ?? ''} | ${user.job_title ?? ''}</div>
                  <div style="color: gray;"><small>Po Box 571308 Murray, UT 84157</small></div>
                  <div><small><a href="${user.email ?? ''}">${user.email ?? ''}</a> | <a
                          href="https://www.eazemed.com">www.eazemed.com</a></small></div>
                  <div><small>P. ${user.phone ?? ''} ext. ${user.extension ?? ''} | F. ${user.fax ?? ''}</small></div>
              </td>
          </tr>
      </tbody>
  </table>
</div>`

  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => {
          setToggle(!toggle)
        }}
      >
        {label}
      </a>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>{label}</ModalHeader>
        <ModalBody>
          <Formik validationSchema={SendEmailSchema} initialValues={{ content: text, send_from: user.email, subject: `Workers Compensation Claim B-${claim.row.id}`, type, send_to: type === 'employer' ? claim.row.employer_email : claim.row.patient_email }} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <TextField
                        type={"text"}
                        label="Send From"
                        placeholder={"Send From"}
                        name="send_from"
                        disable
                      />
                    </Col>
                    <Col md="6">
                      <TextField
                        type={"text"}
                        label="Send To"
                        placeholder={"Send To"}
                        name="send_to"
                      />
                    </Col>
                    <Col md="6">
                      <TextField
                        type={"text"}
                        label="Email CC"
                        placeholder={"Email CC"}
                        name="email_cc"
                      />
                    </Col>
                    <Col md="6">
                      <TextField
                        type={"text"}
                        label="Subject"
                        placeholder={"Subject"}
                        name="subject"
                      />
                    </Col>
                    <Col md="12">
                      <Editor label="Content" name="content" height="200" />
                    </Col>
                  </Row>

                  <div className="mt-3">
                    <LoadingButton title={`Send Email`} isLoading={isSubmitting} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}